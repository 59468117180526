import { Component, PropsWithChildren } from "react";
import * as Sentry from "@sentry/nextjs";
import styles from "./error-boundary.module.sass";

interface IErrorBoundaryProps {
    // eslint-disable-next-line react/no-unused-prop-types
    name?: string;
}

interface IErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren<IErrorBoundaryProps>, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        Sentry.captureException(error, errorInfo);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            // You can render any custom fallback UI
            return <h1 className={styles.text}>Something went wrong.</h1>;
        }

        return children;
    }
}

export default ErrorBoundary;
