import {
    FC,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useRouter } from "next/router";
import { ICasinoGame } from "@finbackoffice/clientbff-client";
import Translate from "components/base/translate/Translate";
import SiteNotification from "components/base/notification/SiteNotification";
import CasinoGameItem from "components/pages/casino/game-item/CasinoGameItem";
import CasinoWidgetContainer from "components/pages/casino/base/casino-widget-container/CasinoWidgetContainer";
import { InitialDataContext } from "contexts";
import { CasinoGamesRowContext, CasinoGamesRowVariantEnum } from "../../CasinoGamesRow";
import styles from "./compact.module.sass";

const Compact: FC = () => {
    const {
        casinoGamesState,
        hasMore,
        endRef,
        notification,
        isSlots,
        label,
        hideTag,
        variant,
        id,
        href,
        options,
        wrapperClassName,
        tag,
        backImg,
        imgSrc,
    } = useContext(CasinoGamesRowContext);
    const { siteHeaderHeight } = useContext(InitialDataContext);
    const router = useRouter();
    const [expanded, setExpanded] = useState(false);
    const containerRef: RefObject<HTMLDivElement> = useRef(null);
    const isReadyTrigeredOnce = useRef(false);
    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
        const onHashChangeStart = (url?: string) => {
            if (url?.includes("#")) {
                const scrollPosition = (containerRef.current?.offsetTop ?? 0) - siteHeaderHeight;

                if (window.scrollY !== scrollPosition) {
                    window.scrollTo({
                        top: scrollPosition,
                        behavior: "smooth",
                    });
                }
            }
        };

        if (variant === CasinoGamesRowVariantEnum.Vertical) {
            onHashChangeStart(router.asPath);
            router.events.on("hashChangeComplete", onHashChangeStart);
        }

        return () => {
            if (variant === CasinoGamesRowVariantEnum.Vertical) {
                router.events.off("hashChangeComplete", onHashChangeStart);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.events]);

    const gameItemLayout = useMemo(() => {
        switch (variant) {
            case CasinoGamesRowVariantEnum.RoundedHorizontal:
                return "rounded";
            case CasinoGamesRowVariantEnum.SquaredHorizontal:
                return "squared";
            default:
                return "default";
        }
    }, [variant]);

    const widgetContainerVariant = useMemo(() => {
        switch (variant) {
            case CasinoGamesRowVariantEnum.Vertical:
                return "allGamesContainer";
            case CasinoGamesRowVariantEnum.NarrowVertical:
                return "narrowVertical";
            case CasinoGamesRowVariantEnum.Wide:
                return "wideRow";
            case CasinoGamesRowVariantEnum.Narrow:
                return "narrowRow";
            default:
                return undefined;
        }
    }, [variant]);

    const handleOnReady = useCallback(() => {
        if (variant !== CasinoGamesRowVariantEnum.Vertical && !isReadyTrigeredOnce.current) {
            isReadyTrigeredOnce.current = true;
            if (!containerHeight && containerRef.current?.clientHeight) {
                setContainerHeight(containerRef.current?.clientHeight);
            }
        }
    }, [containerHeight, variant]);

    const getFallbackSrc = useCallback(
        (game: ICasinoGame) => {
            if (variant === CasinoGamesRowVariantEnum.Wide) {
                return game.image_filled;
            }

            return undefined;
        },
        [variant],
    );

    const imgSrcFolderMemo = useMemo(() => {
        if (variant === CasinoGamesRowVariantEnum.Narrow) {
            return "narrow";
        } else if (options?.megaways && variant === CasinoGamesRowVariantEnum.Wide && !expanded) {
            return "megaways";
        }

        return undefined;
    }, [expanded, options?.megaways, variant]);

    const renderGame = useCallback(
        (game: ICasinoGame) => {
            return (
                <CasinoGameItem
                    key={game.id}
                    game={game}
                    hideTag={hideTag}
                    onReady={handleOnReady}
                    hidePlayButtons={!isSlots}
                    layout={gameItemLayout}
                    fallbackSrc={getFallbackSrc(game)}
                    backImg={backImg}
                    imgSrc={imgSrc}
                    imgSrcFolder={imgSrcFolderMemo}
                />
            );
        },
        [
            backImg,
            gameItemLayout,
            getFallbackSrc,
            handleOnReady,
            hideTag,
            imgSrc,
            imgSrcFolderMemo,
            isSlots,
        ],
    );

    return (
        <CasinoWidgetContainer
            ref={containerRef}
            expand={expanded}
            height={containerHeight}
            loading={casinoGamesState.loading}
            wrapperClassName={wrapperClassName}
            variant={
                !expanded
                    ? widgetContainerVariant
                    : variant === CasinoGamesRowVariantEnum.Narrow
                      ? widgetContainerVariant
                      : undefined
            }
            defaultHeaderProps={{
                href,
                totalCount: casinoGamesState.totalCount,
                expanded,
                setExpanded,
                id,
                label,
                tag,
            }}>
            {!casinoGamesState.loading && !casinoGamesState.games.length && (
                <div className="no-game">
                    <Translate tid="no_games" />
                </div>
            )}
            {Boolean(casinoGamesState.games.length) && (
                <>
                    {casinoGamesState.games.map(renderGame)}
                    {(expanded || (!expanded && variant === CasinoGamesRowVariantEnum.Vertical)) &&
                        hasMore && <div ref={endRef} />}
                </>
            )}
            {notification.type && (
                <SiteNotification
                    type={notification.type}
                    title={notification.error}
                    message={notification.message}
                    wrapperClassname={styles.notification}
                />
            )}
        </CasinoWidgetContainer>
    );
};

export default Compact;
