import { getCookie, setCookie } from "@finbackoffice/fe-core";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import { FC, useEffect, useState } from "react";
import Button from "components/base/button/Button";
import Translate from "components/base/translate/Translate";
import styles from "./cookies-consent.module.sass";

const CookiesConsent: FC = () => {
    const [accepted, setAccepted] = useState<boolean | null>(null);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    useEffect(() => {
        setAccepted(getCookie("cookiesAccepted") === "yes");
    }, []);

    if (accepted || accepted === null) {
        return null;
    }

    return (
        <div className={styles.cookieBanner} data-testid="cookie-constent">
            <Translate
                tid="cookies_consent_text"
                replace={{
                    app_name: COMMON_SITE_CONFIGS.appName,
                }}
                dangerous>
                <div className={styles.cookieMessage} />
            </Translate>
            <Button
                type="button"
                variant="primary"
                data-testid="cookies-accept-button"
                onClick={() => {
                    setCookie("cookiesAccepted", "yes", 30);
                    setAccepted(true);
                }}>
                <Translate tid="cookies_consent_accept" />
            </Button>
        </div>
    );
};

export default CookiesConsent;
