export enum RouterQuery {
    Login = "login",
    Forgot = "forgot",
    Signup = "signup",
    Account = "account",
    Wallet = "wallet",
    LiveCasio = "livecasino",
    Slots = "slots",
    Sport = "sport",
    Bets = "bets",
    Search = "search",
    Promo = "promo",
    Promo2 = "promo-2",
    Promo3 = "promo-3",
    Promo4 = "promo-4",
    Promo5 = "promo-5",
    Promo6 = "promo-6",
    Casino = "casino",
    Casino2 = "casino-2",
    Referral = "referral",
    Inbox = "inbox",
    Sent = "sent",
    Starred = "starred",
    Compose = "compose",
    Personal = "personal",
    Transactions = "transactions",
    ResponsibleGaming = "responsible-gaming",
    "2FA" = "2fa",
}
