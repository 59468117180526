import { sortAlphabeticallyByProp, CasinoGameTypeEnum } from "@finbackoffice/fe-core";
import {
    FC,
    PropsWithChildren,
    createContext,
    useMemo,
    useState,
    useContext,
    useEffect,
    useCallback,
} from "react";
import { ICategorySummaryResponse } from "@finbackoffice/clientbff-client";
import {
    CasinoFavoritesContext,
    CasinoScopesMap,
    ClientBFFContext,
    isCasinoGameType,
} from "@finbackoffice/site-core";
import { CasinoGameTag } from "@finbackoffice/enums";
import { useRouter } from "next/router";
import { useCasinoMethods } from "hooks";
import { RouteNames } from "utils/constants";

type ICasinoToolsContext = {
    gameTypes: CasinoGameTypeEnum[];
    providerOptions: ICategorySummaryResponse[];
    handlePlayNow: (gameId: string, isSlots: boolean) => void;
    handlePlayForFun: (gameId: string, isSlots: boolean) => void;
};

export const CasinoToolsContext = createContext<ICasinoToolsContext>(null as any);

export const CasinoToolsProvider: FC<PropsWithChildren> = ({ children }) => {
    const router = useRouter();
    const isFavoritesPage = router.pathname.includes(`/${RouteNames.FAVORITES}`);
    const client = useContext(ClientBFFContext);
    const { handlePlayForFun, handlePlayNow } = useCasinoMethods();
    const scope = useMemo(() => router.pathname.split("/")[2], [router.pathname]);
    const isQueryCasinoGameType = useMemo(
        () => isCasinoGameType(router.query.filter as CasinoGameTypeEnum),
        [router.query.filter],
    );
    const defaultGameTypes = useMemo(() => {
        switch (scope) {
            case RouteNames.SLOTS:
                if (isQueryCasinoGameType) {
                    return [router.query.filter as CasinoGameTypeEnum];
                }
                return CasinoScopesMap.slots;

            case RouteNames.LIVE_CASINO:
                if (isQueryCasinoGameType) {
                    if (router.query.filter === CasinoGameTypeEnum.LIVE_CASINO) {
                        return CasinoScopesMap.livecasino;
                    }
                    return [router.query.filter as CasinoGameTypeEnum];
                }
                return [CasinoGameTypeEnum.LIVE_CASINO_TABLE, CasinoGameTypeEnum.LIVE_CASINO_V2];
            default:
                return [];
        }
    }, [isQueryCasinoGameType, router.query.filter, scope]);
    const [providerOptions, setProviderOptions] = useState<ICategorySummaryResponse[]>([]);
    const { favoriteGamesState } = useContext(CasinoFavoritesContext);

    const removeQuery = useCallback(
        (key: string) => {
            const query = { ...router.query };
            delete query[key];
            router.push({ query }, undefined, { shallow: true });
        },
        [router],
    );

    useEffect(() => {
        const loadProviderOptions = async () => {
            let response = await client.getCasinoGameSummaryByCategory({
                mobile: true,
                types: defaultGameTypes,
                megaways: router.query.filter === "megaways",
                bonusbuy: router.query.filter === "bonusbuy",
                has_jackpot: router.query.filter === "has_jackpot",
                tag: router.query.tag as CasinoGameTag,
            });

            if (
                router.query.provider &&
                response.find((item) => item.category === router.query.provider) === undefined
            ) {
                removeQuery("provider");
            }

            if (isFavoritesPage) {
                const availableCategories = favoriteGamesState.games.map((game) => game.category);
                response = response.filter((item) => availableCategories.includes(item.category));
                response.forEach(
                    (item) =>
                        (item.total_games = availableCategories.filter(
                            (provider) => provider === item.category,
                        ).length),
                );
            }

            setProviderOptions(response.sort((a, b) => sortAlphabeticallyByProp("category")(a, b)));
        };

        if (!!defaultGameTypes.length) {
            try {
                loadProviderOptions();
            } catch (error) {
                console.error(error);
            }
        } else {
            setProviderOptions([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query.tag, router.query.filter, defaultGameTypes]);

    const value = useMemo(
        () => ({
            gameTypes: defaultGameTypes,
            providerOptions,
            handlePlayForFun,
            handlePlayNow,
        }),
        [defaultGameTypes, providerOptions, handlePlayForFun, handlePlayNow],
    );

    return <CasinoToolsContext.Provider value={value}>{children}</CasinoToolsContext.Provider>;
};
