import { FC, useMemo } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import Faq, { FaqId } from "components/base/faq/Faq";
import styles from "components/pages/sport/prematch/faq/prematch-faq.module.sass";

const PrematchFaq: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const faq: FaqId[] = useMemo(() => {
        const commonReplace = {
            app_name: COMMON_SITE_CONFIGS.appName,
        };
        const commonAnswer = {
            dangerous: true,
            replace: commonReplace,
        };
        const result: FaqId[] = [
            {
                id: "1",
                question: {
                    replace: commonReplace,
                },
                answer: commonAnswer,
            },
            {
                id: "2",
                question: {
                    replace: commonReplace,
                },
                answer: commonAnswer,
            },
            {
                id: "3",
                question: {
                    replace: commonReplace,
                },
                answer: {
                    replace: commonReplace,
                },
            },
            {
                id: "4",
                question: {
                    replace: commonReplace,
                },
                answer: commonAnswer,
            },
            {
                id: "5",
                question: {
                    replace: commonReplace,
                },
                answer: {
                    replace: commonReplace,
                },
            },
            {
                id: "6",
                question: {
                    replace: commonReplace,
                },
                answer: commonAnswer,
            },
            {
                id: "7",
                question: {
                    replace: commonReplace,
                },
                answer: commonAnswer,
            },
            {
                id: "8",
                question: {
                    replace: commonReplace,
                },
                answer: commonAnswer,
            },
        ];
        return result;
    }, [COMMON_SITE_CONFIGS.appName]);

    return (
        <section className={styles.faqContainer}>
            <p>
                <Translate tid="prematch_faq_paragraph" namespace={TranslationScopes.PreMatch} />
            </p>
            <Faq namespace={TranslationScopes.PreMatch} ids={faq} />
        </section>
    );
};

export default PrematchFaq;
