import { FeedContext, useWebsocketUpdates } from "@finbackoffice/site-core";
import { IClientError, ISocketResponse } from "@finbackoffice/websocket-client";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { EventStatus } from "@finbackoffice/enums";
import { FeedTypeEnum, useDate, useSocketStorage } from "hooks";
import { SportResultsContext } from "contexts";
import { useGetCurrentFeedType } from "./useGetCurrentFeed";

interface ICalendarTreePayload {
    from: number;
    to: number;
}

export const useSportResults = () => {
    const { getTime, startOfDay, endOfDay } = useDate();
    const feed: FeedTypeEnum = useGetCurrentFeedType();
    const { getSports, connected } = useSocketStorage(FeedTypeEnum.results);
    const { setResults } = useContext(FeedContext);
    const { activeDate } = useContext(SportResultsContext);

    const handler = useCallback(
        (_nothing: ISocketResponse<"calendar_tree">, error: IClientError) => {
            if (!error) {
                const sports = getSports();
                setResults({
                    loading: false,
                    sports,
                });
            } else {
                console.error(error);
            }
        },
        [getSports, setResults],
    );

    const payload: ICalendarTreePayload = useMemo(
        () => ({
            from: getTime(startOfDay(activeDate)),
            to: getTime(endOfDay(activeDate)),
            status: [EventStatus.Ended, EventStatus.Settled],
        }),
        [activeDate, endOfDay, getTime, startOfDay],
    );

    const { subscribe, unsubscribe } = useWebsocketUpdates(
        "calendar_tree",
        handler,
        false,
        payload,
    );

    useEffect(() => {
        if (feed === FeedTypeEnum.results) {
            subscribe();
        } else {
            unsubscribe();
            setResults({
                loading: true,
                sports: [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, feed]);

    useEffect(() => {
        return () => {
            if (feed === FeedTypeEnum.results && activeDate) {
                setResults((state) => ({
                    ...state,
                    loading: true,
                    sports: [],
                }));
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDate]);
};
