import { useCallback, useContext, useEffect, useState } from "react";
import { SportTypes } from "@finbackoffice/fe-core";
import { LocaleContext } from "@finbackoffice/site-core";
import { SIRWidgetContext } from "contexts";
import { FeedTypeEnum } from "hooks";

declare function SIR(
    action: WidgetActionEnum,
    selector: string,
    widgetType?: WidgetTypeEnum,
    props?: SirWidgetProps,
    onInit?: (...args: unknown[]) => any,
): any;

type OnTrackParam1 = "data_change" | "odds_click" | "social_share" | "license_error";
type OnTrackParam2 = Record<string, unknown>;

interface SirWidgetProps {
    layout?: "topdown";
    collapseTo?: "momentum";
    tabsPosition?: "top" | "disable";
    matchId: string;
    onTrack: (
        param1: "data_change" | "odds_click" | "social_share" | "license_error",
        param2: Record<string, unknown>,
    ) => void;
    silent?: boolean;
    expanded?: boolean;
    branding?: {
        tabs: { option: string; variant: string };
    };
    disablePitchNoise?: boolean;
    disablePitchSpotlights?: boolean;
    scoreboard?: string;
    disableComponents?: boolean;
    detailedScoreboard?: "disable";
    activeSwitcher?: "scoreDetails";
    forceTeamInvert?: boolean;
}

export enum WidgetActionEnum {
    ADD_WIDGET = "addWidget",
    REMOVE_WIDGET = "removeWidget",
}

export enum WidgetTypeEnum {
    LMT_PLUS = "match.lmtPlus",
    LMT_COMPACT = "match.lmtCompact",
}

const debounce = (func: (...args: any[]) => any, delay: number) => {
    let timeout: NodeJS.Timeout | null;
    return function executed(this: any, ...args: any[]) {
        const later = () => {
            timeout = null;
            func.apply(this, args);
        };
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(later, delay);
    };
};

export const useSIRWidget = (
    containerSelector: string,
    onTouchMoveCallback?: (startY: number, touch: Touch) => void,
) => {
    const { locale } = useContext(LocaleContext);
    const { checkIfTeamInverted } = useContext(SIRWidgetContext);
    const [isLicenced, setIsLicenced] = useState<boolean>(true);
    const [hideContent, setHideContent] = useState(false);

    const onWindowResize = useCallback(() => {
        (document.querySelector(".sr-widget.sr-widget-lmtplus") as HTMLElement) &&
            ((document.querySelector(".sr-widget.sr-widget-lmtplus") as HTMLElement).style.height =
                `${
                    document.querySelector(".sr-widget-lmtplus .sr-bb.sr-lmt-plus")?.clientHeight
                }px`);
    }, []);

    useEffect(() => {
        if (!document.getElementById("sir-widget-script")) {
            const script = document.createElement("script");
            script.id = "sir-widget-script";
            script.appendChild(
                document.createTextNode(`(function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
                    g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
                    )})(window,document,"script", "https://widgets.sir.sportradar.com/7f58e65fe4ac1587c6f000924fbb13d4/widgetloader", "SIR", {
                        theme : false,
                        language: "${locale}"
                    })`),
            );

            document.body.appendChild(script);
        }

        let touchStartY = 0;

        const onTouchStart = (event: any) => {
            const touch = event.targetTouches[0];
            touchStartY = touch.pageY;
        };

        const onTouchMove = (event: any) => {
            const touch = event.targetTouches[0];
            onTouchMoveCallback?.(touchStartY, touch);
        };

        const resizeDebouncedHandler = debounce(onWindowResize, 320);

        document.querySelector(containerSelector)?.addEventListener("touchstart", onTouchStart);
        document.querySelector(containerSelector)?.addEventListener("touchmove", onTouchMove);
        window.addEventListener("resize", resizeDebouncedHandler);

        return () => {
            document
                .querySelector(containerSelector)
                ?.removeEventListener("touchstart", onTouchStart);
            document
                .querySelector(containerSelector)
                ?.removeEventListener("touchmove", onTouchMove);
            window.removeEventListener("resize", resizeDebouncedHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function initLmtPlusHeaderWidget(
        channel: FeedTypeEnum,
        sportId: number,
        regionId: number,
        matchId: string,
        selector = "",
        onInit?: (...args: unknown[]) => any,
        onError?: (...args: unknown[]) => any,
    ) {
        SIR(
            WidgetActionEnum.ADD_WIDGET,
            `${selector} .sr-widget-lmtplus-header`,
            WidgetTypeEnum.LMT_PLUS,
            {
                ...([SportTypes.SOCCER, SportTypes.BASEBALL].includes(sportId)
                    ? {
                          scoreboard: "extended",
                      }
                    : {}),
                ...(channel === FeedTypeEnum.prematch ? { detailedScoreboard: "disable" } : {}),
                disableComponents: true,
                ...(checkIfTeamInverted?.(sportId, regionId) ? { forceTeamInvert: true } : {}),
                tabsPosition: "disable",
                activeSwitcher: "scoreDetails",
                matchId,
                onTrack: (param1: any) => {
                    if (param1 === "license_error") {
                        setIsLicenced?.(false);
                        setHideContent(true);
                        onError?.();
                    }
                },
                silent: true,
            },
            onInit,
        );
    }

    function initLmtPlusWidget(
        sportId: number,
        regionId: number,
        matchId: string,
        selector = "",
        onInit?: (...args: unknown[]) => any,
        onError?: (...args: unknown[]) => any,
    ) {
        SIR(
            WidgetActionEnum.ADD_WIDGET,
            `${selector} .sr-widget-lmtplus`,
            WidgetTypeEnum.LMT_PLUS,
            {
                scoreboard: "disable",
                detailedScoreboard: "disable",
                ...(checkIfTeamInverted?.(sportId, regionId) ? { forceTeamInvert: true } : {}),
                matchId,
                onTrack: (param1: any) => {
                    if (param1 === "license_error") {
                        setIsLicenced?.(false);
                        setHideContent(true);
                        onError?.();
                    }
                },
                silent: true,
            },
            onInit,
        );
    }

    function initLmtCompactWidget(
        sportId: number,
        regionId: number,
        matchId: string,
        selector = "",
        onInit?: (...args: unknown[]) => any,
        onError?: (...args: unknown[]) => any,
    ) {
        SIR(
            WidgetActionEnum.ADD_WIDGET,
            `${selector} .sr-widget-compact`,
            WidgetTypeEnum.LMT_COMPACT,
            {
                ...(checkIfTeamInverted?.(sportId, regionId) ? { forceTeamInvert: true } : {}),
                matchId,
                onTrack: (param1: OnTrackParam1, param2: OnTrackParam2) => {
                    if (
                        param1 === "license_error" ||
                        (param1 === "data_change" && !!param2.error)
                    ) {
                        setIsLicenced?.(false);
                        setHideContent(true);
                        onError?.();
                    }
                },
                silent: true,
            },
            onInit,
        );
    }

    function removeWidget(selector: string) {
        SIR(WidgetActionEnum.REMOVE_WIDGET, selector);
    }

    return {
        initLmtPlusHeaderWidget,
        initLmtCompactWidget,
        initLmtPlusWidget,
        onWindowResize,
        removeWidget,
        isLicenced,
        hideContent,
    };
};
