import { useCallback, useContext } from "react";
import {
    format,
    getTime,
    endOfDay,
    addDays,
    startOfDay,
    subDays,
    isToday,
    isTomorrow,
    setMinutes,
    setHours,
    setSeconds,
    differenceInDays,
    setDefaultOptions,
    formatDistanceToNowStrict,
    differenceInYears,
} from "date-fns";
import { ru, enGB, ko } from "date-fns/locale";
import { LocaleContext, useTranslation } from "@finbackoffice/site-core";

export const DEFAULT_DATE_FORMAT = "dd MMM yyyy";

const locales: Record<string, any> = { en: enGB, ko, ru };

export const useDate = () => {
    const { t } = useTranslation();
    const { locale } = useContext(LocaleContext);
    setDefaultOptions({ locale: locales[locale] || locales.en });

    const formatDate = useCallback(
        (date: number | Date, formatStr: string, autoFormat: boolean = true) => {
            if (autoFormat) {
                const hasDay = formatStr.includes("dd");
                const hasTime = formatStr.includes("HH:mm");
                const time = hasTime ? format(date, " HH:mm") : "";
                if (hasDay && isToday(date)) return `${t("base_today")}${time}`;
                if (hasDay && isTomorrow(date)) return `${t("base_tomorrow")}${time}`;
            }

            return format(date, formatStr);
        },
        [t],
    );

    return {
        formatDate,
        getTime,
        endOfDay,
        addDays,
        startOfDay,
        subDays,
        setHours,
        setMinutes,
        setSeconds,
        differenceInDays,
        differenceInYears,
        formatDistanceToNowStrict,
    };
};
