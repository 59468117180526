import { useCallback, useContext, useEffect } from "react";
import { FeedContext, useWebsocketUpdates } from "@finbackoffice/site-core";
import { useRouter } from "next/router";
import { IClientError, ISocketResponse } from "@finbackoffice/websocket-client";
import { FeedTypeEnum, useSocketStorage } from "hooks";
import { RouteNames } from "utils/constants";
import { useGetCurrentFeedType } from "./useGetCurrentFeed";

export const useLiveSports = (): void => {
    const router = useRouter();
    const feed: FeedTypeEnum = useGetCurrentFeedType();
    const { getSports, getTopLeagues, connected } = useSocketStorage(FeedTypeEnum.live);
    const { setLive } = useContext(FeedContext);
    const isCalendar = router.pathname.includes(RouteNames.CALENDAR);

    const handler = useCallback(
        (_nothing: ISocketResponse<"live">, error: IClientError) => {
            if (!error) {
                const sports = getSports();
                const topLeagues = getTopLeagues();
                setLive({
                    loading: false,
                    sports,
                    topLeagues,
                    totalGames: sports.reduce((acc, s) => acc + s.games_count, 0),
                });
            } else {
                console.error(error);
            }
        },
        [getSports, getTopLeagues, setLive],
    );
    const { subscribe, unsubscribe } = useWebsocketUpdates("live", handler, false);

    useEffect(() => {
        if (feed === FeedTypeEnum.live || isCalendar) {
            subscribe();
        } else {
            unsubscribe();
            setLive({
                loading: true,
                sports: [],
                totalGames: 0,
                topLeagues: [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, feed]);

    useEffect(() => {
        return () => {
            if (feed === FeedTypeEnum.live || isCalendar) {
                setLive({
                    loading: true,
                    sports: [],
                    totalGames: 0,
                    topLeagues: [],
                });
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
