import {
    BrowserStorageContext,
    BrowserStorageIdsEnum,
    ClientBFFContext,
} from "@finbackoffice/site-core";
import { DAY_IN_MS } from "@finbackoffice/site-server-core";
import {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { IReelResponse } from "@finbackoffice/clientbff-client";
import { IReelsDataState } from "types/reels.data";

type ISeenReels = {
    id: number;
    last_seen_timestamp?: number;
    is_liked?: boolean;
};

interface IReelsContext {
    markReelsSeen: (id: number) => void;
    toggleReelLiked: (id: number) => void;
    seenReels: ISeenReels[];
    games: IReelResponse[];
    activeGame: IReelResponse | null;
    setActiveGame: Dispatch<SetStateAction<IReelResponse | null>>;
}

export const ReelsContext = createContext<IReelsContext>(null as any);

type IProps = {
    reels: IReelsDataState;
    defaultActiveReel?: IReelResponse;
};

export const ReelsProvider: FC<PropsWithChildren & IProps> = ({
    children,
    reels: gamesState,
    defaultActiveReel,
}) => {
    const client = useContext(ClientBFFContext);

    const [activeGame, setActiveGame] = useState<IReelResponse | null>(defaultActiveReel || null);
    const [seenReels, setSeenReels] = useState<ISeenReels[]>([]);
    const { set, get } = useContext(BrowserStorageContext);

    const sortedGames = useMemo(() => {
        return gamesState.games.sort((a: IReelResponse, b: IReelResponse) => {
            const isASeen = seenReels.find((reel) => reel.id === a.id);
            const isBSeen = seenReels.find((reel) => reel.id === b.id);
            if (isASeen) {
                return 1;
            } else if (isBSeen) {
                return -1;
            }

            return 0;
        });
    }, [gamesState.games, seenReels]);

    useEffect(() => {
        let savedSeenReels = get(BrowserStorageIdsEnum.REELS);

        if (savedSeenReels) {
            savedSeenReels = savedSeenReels.filter((reel: ISeenReels) => {
                if (reel.last_seen_timestamp) {
                    return Date.now() - reel.last_seen_timestamp < 30 * DAY_IN_MS;
                }
                return true;
            });
            setSeenReels(savedSeenReels);
        } else {
            set(BrowserStorageIdsEnum.REELS, JSON.stringify([]));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const markReelsSeen = useCallback(
        (id: number) => {
            const index = seenReels.findIndex((reel) => reel.id === id);
            const reelsExists = index !== -1;
            if (!reelsExists) {
                const updatedState = [...seenReels, { id, last_seen_timestamp: Date.now() }];
                setSeenReels(updatedState);
                set(BrowserStorageIdsEnum.REELS, JSON.stringify(updatedState));
            } else {
                const updatedState = seenReels.toSpliced(index, 1, {
                    ...seenReels[index],
                    last_seen_timestamp: Date.now(),
                });
                setSeenReels(updatedState);
                set(BrowserStorageIdsEnum.REELS, JSON.stringify(updatedState));
            }
        },
        [seenReels, set],
    );

    const toggleReelLiked = useCallback(
        async (id: number) => {
            const index = seenReels.findIndex((reel) => reel.id === id);
            const reelExistsInStorage = index !== -1;

            let result = true;
            if (!reelExistsInStorage) {
                const updatedState = [...seenReels, { id, is_liked: result }];
                setSeenReels(updatedState);
                set(BrowserStorageIdsEnum.REELS, JSON.stringify(updatedState));
            } else {
                result = !seenReels[index].is_liked;
                const updatedState = seenReels.toSpliced(index, 1, {
                    ...seenReels[index],
                    is_liked: result,
                });
                setSeenReels(updatedState);
                set(BrowserStorageIdsEnum.REELS, JSON.stringify(updatedState));
            }

            if (result) {
                try {
                    await client.addReelLike(id);
                } catch (error) {
                    console.log(error);
                }
            }
        },
        [client, seenReels, set],
    );

    const value = useMemo(
        () => ({
            markReelsSeen,
            seenReels,
            games: sortedGames,
            toggleReelLiked,
            setActiveGame,
            activeGame,
        }),
        [markReelsSeen, seenReels, sortedGames, toggleReelLiked, activeGame],
    );

    if (!gamesState.games.length) {
        return null;
    }

    return <ReelsContext.Provider value={value}>{children}</ReelsContext.Provider>;
};
