import { useCallback, useState } from "react";

export type INotificationType = "info" | "warning" | "error";

export type INotificationState = {
    type: INotificationType | "";
    error: string;
    message: string;
};

export const useNotification = () => {
    const [notification, setNotification] = useState<INotificationState>({
        type: "",
        error: "",
        message: "",
    });

    const clearNotification = useCallback(() => {
        setNotification({ type: "", error: "", message: "" });
    }, []);

    return { notification, setNotification, clearNotification };
};
