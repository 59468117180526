import { FC } from "react";
import classNames from "classnames";
import styles from "./loading.module.sass";

interface IProps {
    wrapperClassName?: string;
    testId?: string;
}

const Loading: FC<IProps> = ({ wrapperClassName, testId }) => (
    <div
        className={classNames(styles.loading, "loader", wrapperClassName)}
        data-testid={testId || ""}>
        <span />
    </div>
);

export default Loading;
