import { Currency } from "@finbackoffice/enums";
import {
    ConfigContext,
    ExchangeRatesContext,
    UserAccountContext,
    useRuntimeConfig,
    useWebsocketUpdates,
} from "@finbackoffice/site-core";
import { ISocketResponse } from "@finbackoffice/websocket-client";
import { useCallback, useContext, useMemo } from "react";

export const useLatestExchangeRates = (): void => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { siteDefaultCurrency, siteSupportedCurrencies } = useContext(ConfigContext);
    const { userCurrency } = useContext(UserAccountContext);
    const { setExchangeRatesData } = useContext(ExchangeRatesContext);

    const handler = useCallback(
        (exchangeRates: ISocketResponse<"exchange_rates_latest">) => {
            if (exchangeRates) {
                setExchangeRatesData(exchangeRates);
            }
        },
        [setExchangeRatesData],
    );

    const subscriptionParams = useMemo(() => {
        const fiatEnabled = COMMON_SITE_CONFIGS.wallet.fiat;
        const fxCurrency = COMMON_SITE_CONFIGS.wallet.fxCurrency;
        const toCurrencies = [];

        if (fiatEnabled) {
            toCurrencies.push(Currency.USD);
        } else if (fxCurrency) {
            toCurrencies.push(fxCurrency);
        }

        if (fiatEnabled && siteDefaultCurrency && !toCurrencies.includes(siteDefaultCurrency)) {
            toCurrencies.push(siteDefaultCurrency);
        }

        if (userCurrency && !toCurrencies.includes(userCurrency)) {
            toCurrencies.push(userCurrency);
        }

        return {
            fromCurrency: siteSupportedCurrencies,
            toCurrencies,
        };
    }, [siteDefaultCurrency, userCurrency, siteSupportedCurrencies, COMMON_SITE_CONFIGS.wallet]);

    useWebsocketUpdates("exchange_rates_latest", handler, true, subscriptionParams);
};
