import { TranslationScopes } from "@finbackoffice/enums";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import { FC, useMemo } from "react";
import Faq from "components/base/faq/Faq";
import Translate from "components/base/translate/Translate";
import styles from "./home-faq.module.sass";

const HomeFAQ: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");

    const faq = useMemo(() => {
        return [
            {
                id: "1",
                question: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
                answer: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
            },
            {
                id: "2",
                question: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
                answer: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
            },
            {
                id: "3",
                question: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
                answer: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
            },
            {
                id: "4",
                question: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
                answer: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
            },
            {
                id: "5",
                question: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
                answer: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
            },
            {
                id: "6",
                question: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
                answer: {
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                },
            },
        ];
    }, [COMMON_SITE_CONFIGS.appName]);

    return (
        <section className={styles.faqContainer}>
            <Translate tid="home_howToUse" namespace={TranslationScopes.Home} dangerous>
                <div />
            </Translate>
            <h1>
                <Translate
                    tid="home_about_head"
                    namespace={TranslationScopes.Home}
                    replace={{
                        app_name: COMMON_SITE_CONFIGS.appName,
                    }}
                />
            </h1>
            <Translate
                tid="home_about_txt"
                namespace={TranslationScopes.Home}
                dangerous
                replace={{
                    app_name: COMMON_SITE_CONFIGS.appName,
                }}>
                <div className={styles.homeAboutTxt} />
            </Translate>
            <Faq
                namespace={TranslationScopes.Home}
                ids={faq}
                head={{
                    replace: {
                        app_name: COMMON_SITE_CONFIGS.appName,
                    },
                }}
            />
        </section>
    );
};

export default HomeFAQ;
