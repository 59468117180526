export const RouteNames = {
    SPORT: "sport",
    LIVE: "live",
    PREMATCH: "prematch",
    RESULTS: "results",
    TOP: "top",
    UPCOMING: "upcoming",
    CALENDAR: "calendar",
    CASINO: "casino",
    TAG: "tag",
    LIVE_CASINO: "livecasino",
    SLOTS: "slots",
    ALL: "all",
    ACCOUNT: "account",
    BETS: "bets",
    DETAILS: "details",
    PERSONAL: "personal",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "2FA": "2fa",
    MESSAGES: "messages",
    COMPOSE: "compose",
    SEARCH: "search",
    REFERRAL: "referral",
    TRANSACTIONS: "transactions",
    POLICIES: "policies",
    SPORTSBOOK: "sportsbook",
    TERMS: "terms",
    PRIVACY: "privacy",
    PAYMENTS: "payments",
    RESPONSIBLE_GAMING: "responsible-gaming",
    COOKIES: "cookies",
    SELF_EXCLUSION: "self-exclusion",
    KYC: "kyc",
    FAIRNESS_RNG_TESTING: "fairness-rng-testing",
    DISPUTE_RESOLUTION: "dispute-resolution",
    ANTI_MONEY_LAUNDERING: "anti-money-laundering",
    BONUSES: "bonuses",
    ABOUT: "about",
    CONTACT: "contact",
    CASHBACK: "cashback",
    BONUS_REFERRAL: "bonus-referral",
    PROMOTIONS: "promotions",
    FAVORITES: "favorites",
    AGENT: "agent",
    REPORT: "report",
    PLAYERS: "players",
    CREATE_PLAYER: "create-player",
    REEL: "reel",
};
