import { FC, PropsWithChildren, ReactNode } from "react";
import { NextRouter } from "next/router";
import Footer from "components/footer/Footer";
import MainContent from "components/MainContent";
import { PrematchFeedSubscription } from "../sportsbook/prematch/PrematchLayout";

const CommonLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <MainContent>
            {children}
            <Footer />
        </MainContent>
    );
};

export const getCommonLayout = (page: ReactNode, router: NextRouter) => {
    const isHome = router.pathname === "/";
    const content = <CommonLayout>{page}</CommonLayout>;
    if (isHome) {
        return <PrematchFeedSubscription>{content}</PrematchFeedSubscription>;
    } else {
        return content;
    }
};
