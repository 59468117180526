import classNames from "classnames";
import { FC, HTMLProps } from "react";
import { FieldError } from "react-hook-form";
import { TranslationScopes } from "@finbackoffice/enums";
import { DropdownOption, ITranslateProps } from "@finbackoffice/site-core";
import Translate from "../translate/Translate";
import styles from "./dropdown.module.sass";

interface IProps {
    name: string;
    options: DropdownOption[];
    wrapperClassname?: string;
    error?: Partial<FieldError> | { type: string; message: string };
    placeholder?: string;
    required?: boolean;
    label?: string | ITranslateProps;
}

const Dropdown: FC<IProps & Omit<HTMLProps<HTMLSelectElement>, "label">> = ({
    options,
    wrapperClassname,
    placeholder,
    error,
    required,
    label,
    name,
    ...rest
}) => {
    return (
        <div className={classNames(styles.dropdownContainer, wrapperClassname)}>
            {label && (
                <label htmlFor={name} className={styles.label}>
                    <Translate
                        tid={typeof label === "string" ? label : label.tid}
                        namespace={
                            typeof label !== "string" ? label.namespace : TranslationScopes.Common
                        }
                    />
                    :
                </label>
            )}
            <div className={classNames(required && "required")}>
                <select
                    name={name}
                    id={name}
                    className={classNames(styles.dropdown, error && styles.fieldError)}
                    // onChange={handleChange}
                    {...rest}
                    // defaultValue={placeholder ? "" : undefined}
                >
                    {placeholder && (
                        <option disabled value="">
                            {placeholder}
                        </option>
                    )}
                    {options.map((item) => (
                        <option key={item.value} value={item.value}>
                            <Translate
                                tid={typeof item.label === "string" ? item.label : item.label.tid}
                                namespace={
                                    typeof item.label !== "string"
                                        ? item.label.namespace
                                        : TranslationScopes.Common
                                }
                            />
                        </option>
                    ))}
                </select>
                <span className={styles.arrow} />
            </div>
            {error && (
                <span className={classNames(styles.error, "field-error")}>{error.message}</span>
            )}
        </div>
    );
};

export default Dropdown;
