import { FC, useContext } from "react";
import { ModalsContext } from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import Button from "components/base/button/Button";
import styles from "./floating-login.module.sass";

const FloatingLogin: FC = () => {
    const { loginModalRef, signupModalRef } = useContext(ModalsContext);

    return (
        <div className={styles.floatingLogin}>
            <Button
                type="button"
                onClick={() => loginModalRef.current?.open()}
                variant="secondary"
                data-testid="header-login-button">
                <Translate tid="menu_login" />
            </Button>
            <Button
                data-testid="signupButton"
                type="button"
                onClick={() => signupModalRef.current?.open()}
                variant="primary">
                <Translate tid="header_registerNow" />
            </Button>
        </div>
    );
};

export default FloatingLogin;
