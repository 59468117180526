import { useEffect, useCallback, useState } from "react";
import { sortByScheduledTime } from "@finbackoffice/fe-core";
import { useWebsocketUpdates } from "@finbackoffice/site-core";
import { IClientError, ISocketResponse, IEventFeedResponse } from "@finbackoffice/websocket-client";
import { EventStatus } from "@finbackoffice/enums";

type ICalendarGames = {
    games: IEventFeedResponse[];
    loading: boolean;
};

export const useCalendar = (date: { from: number; to: number }): ICalendarGames => {
    const [calendarFeed, setCalendarFeed] = useState<ICalendarGames>({
        games: [],
        loading: false,
    });
    const [gamesMap] = useState<Map<number, IEventFeedResponse>>(new Map());

    const handleCalendarUpdates = useCallback(
        (events: ISocketResponse<"calendar">, error: IClientError) => {
            if (!error) {
                events.forEach((event) => {
                    if (
                        event.status === EventStatus.NotActive ||
                        event.status === EventStatus.Live
                    ) {
                        gamesMap.delete(event.id);
                    } else {
                        gamesMap.set(event.id, event);
                    }
                });
                const games = Array.from(gamesMap.values());
                const sorted_games = games.sort(sortByScheduledTime);
                setCalendarFeed({ games: sorted_games, loading: false });
            } else {
                console.error(error);
            }
        },
        [gamesMap, setCalendarFeed],
    );

    useEffect(() => {
        return () => {
            if (date) {
                setCalendarFeed((state) => ({
                    ...state,
                    loading: true,
                }));
                gamesMap.clear();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    useWebsocketUpdates("calendar", handleCalendarUpdates, true, date);

    return { games: calendarFeed.games, loading: calendarFeed.loading };
};
