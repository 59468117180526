import { IReelResponse } from "@finbackoffice/clientbff-client";
import { SiteContentScopes } from "@finbackoffice/enums";
import { ClientBFFContext, LocaleContext, useRuntimeConfig } from "@finbackoffice/site-core";
import { useContext, useEffect, useState } from "react";

type IReelsDataState = {
    games: IReelResponse[];
    totalCount: number;
    loading: boolean;
};

export const useReels = (scope?: SiteContentScopes[]) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const client = useContext(ClientBFFContext);
    const { locale } = useContext(LocaleContext);

    const [gamesState, setGamesState] = useState<IReelsDataState>({
        games: [],
        totalCount: 0,
        loading: true,
    });

    useEffect(() => {
        const loadData = async () => {
            setGamesState((state) => ({
                ...state,
                loading: true,
            }));
            try {
                // const deviceTag = device === "desktop" ? PopupTags.Desktop : PopupTags.Mobile;
                const deviceTag = "mobile";

                let tags = [deviceTag, COMMON_SITE_CONFIGS.appName];

                if (scope) {
                    tags = [...tags, ...scope];
                }

                const response = await client.getReels({
                    language: locale,
                    tags,
                });

                setGamesState((state) => ({
                    ...state,
                    games: response.items,
                    totalCount: response.total_count,
                    loading: false,
                }));
            } catch (error) {
                setGamesState((state) => ({
                    ...state,
                    loading: false,
                }));
            }
        };

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { gamesState };
};
