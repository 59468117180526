import { EventStatus } from "@finbackoffice/enums";
import { getTimefilterPayloadForSocket, sortByScheduledTime } from "@finbackoffice/fe-core";
import { useWebsocketUpdates } from "@finbackoffice/site-core";
import {
    IClientError,
    IEventFeedResponse,
    ISubscriptionPayloadParams,
} from "@finbackoffice/websocket-client";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PrematchContext } from "contexts";

const statuses = [EventStatus.NotActive, EventStatus.Live, EventStatus.Settled, EventStatus.Ended];

type IGamesState = {
    loading: boolean;
    data: IEventFeedResponse[];
};

export const usePrematchTournament = (tournamentId: number) => {
    const [games, setGames] = useState<IGamesState>({
        loading: true,
        data: [],
    });
    const { hoursRange } = useContext(PrematchContext);
    const [gamesMap] = useState<Map<number, IEventFeedResponse>>(new Map());

    const gamesUpdateHandler = useCallback(
        (data: IEventFeedResponse[], error: IClientError) => {
            if (!error) {
                data.forEach((event) => {
                    if (statuses.indexOf(event.status) !== -1) {
                        gamesMap.delete(event.id);
                    } else {
                        gamesMap.set(event.id, event);
                    }
                });
                const games = Array.from(gamesMap.values());
                const sorted_games = games.sort(sortByScheduledTime);
                setGames((state) => ({
                    ...state,
                    loading: false,
                    data: sorted_games,
                }));
            } else {
                console.error(error);
            }
        },
        [gamesMap],
    );

    const payload = useMemo(() => {
        let result: ISubscriptionPayloadParams<"tournament">[0] = {
            tournament_id: tournamentId,
        };

        if (hoursRange) {
            result = { ...result, ...getTimefilterPayloadForSocket(hoursRange) };
        }

        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId]); // do not add timeFilter here

    useWebsocketUpdates("tournament", gamesUpdateHandler, true, payload);

    useEffect(() => {
        return () => {
            if (tournamentId) {
                setGames((state) => ({
                    ...state,
                    loading: true,
                    data: [],
                }));
                gamesMap.clear();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId]);

    return { games: games.data, loading: games.loading };
};
