import { FC, PropsWithChildren, ReactNode, useContext, useEffect, useMemo } from "react";
import { FeedContext } from "@finbackoffice/site-core";
import { useNotification, usePrematchSports } from "hooks";
import Footer from "components/footer/Footer";
import MainContent from "components/MainContent";
import PrematchFaq from "components/pages/sport/prematch/faq/PrematchFaq";
import PrematchHeader from "components/pages/sport/prematch/header/PrematchHeader";
import SiteNotification from "components/base/notification/SiteNotification";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";

export const PrematchFeedSubscription: FC<PropsWithChildren> = ({ children }) => {
    usePrematchSports();
    return children;
};

const PrematchLayout: FC<PropsWithChildren> = ({ children }) => {
    const { prematchFeedError } = useContext(FeedContext);
    const { setNotification, notification } = useNotification();

    useEffect(() => {
        if (prematchFeedError) {
            setNotification({
                type: "error",
                error: "Error",
                message: prematchFeedError,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prematchFeedError]);

    const renderContent = useMemo(() => {
        if (notification.type) {
            return (
                <SiteNotification
                    type={notification.type}
                    title={notification.error}
                    message={notification.message}
                />
            );
        } else {
            return children;
        }
    }, [children, notification.error, notification.message, notification.type]);

    return (
        <MainContent>
            <PrematchHeader />
            <FadeInAnimation>
                <div>{renderContent}</div>
            </FadeInAnimation>
            <PrematchFaq />
            <Footer />
        </MainContent>
    );
};

const getPrematchLayout = (page: ReactNode) => (
    <PrematchFeedSubscription>
        <PrematchLayout>{page}</PrematchLayout>
    </PrematchFeedSubscription>
);

export default getPrematchLayout;
