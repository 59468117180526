import {
    ConfigContext,
    AuthContext,
    UserAccountContext,
    useRuntimeConfig,
    ModalsContext,
} from "@finbackoffice/site-core";
import { useRouter } from "next/router";
import { useContext, useCallback } from "react";
import { NotificationContext } from "contexts";
import { RouteNames } from "utils/constants";

export const useCasinoMethods = () => {
    const router = useRouter();
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { siteDefaultCurrency } = useContext(ConfigContext);
    const { notice } = useContext(NotificationContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const { currentWalletId, currentCurrency } = useContext(UserAccountContext);
    const { loginModalRef } = useContext(ModalsContext);

    const fiatEnabled = COMMON_SITE_CONFIGS.wallet.fiat;
    const defaultCurrency = !fiatEnabled
        ? COMMON_SITE_CONFIGS.wallet._crypto.default
        : siteDefaultCurrency;

    const navigateToGame = useCallback(
        (gameId: string, isSlots: boolean, forFun = false) => {
            let subPath = "";
            if (isSlots) {
                subPath = RouteNames.SLOTS;
            } else {
                subPath = RouteNames.LIVE_CASINO;
            }

            if (subPath) {
                router.push({
                    pathname: `/${RouteNames.CASINO}/${subPath}/[gameId]`,
                    query: {
                        ...router.query,
                        gameId,
                        forFun,
                    },
                });
            }
        },
        [router],
    );

    const handlePlayNow = useCallback(
        (gameId: string, isSlots: boolean) => {
            if (isUserLoggedIn) {
                if (currentWalletId) {
                    navigateToGame(gameId, isSlots);
                } else {
                    notice(
                        {
                            type: "warning",
                            title: "Warning",
                            message: `Sorry, but it seems you don't have any ${currentCurrency} in your balance. Please select another currency or the ${defaultCurrency}.`,
                        },
                        "modal",
                    );
                }
            } else {
                loginModalRef.current?.open();
            }
        },
        [
            isUserLoggedIn,
            currentWalletId,
            navigateToGame,
            notice,
            currentCurrency,
            defaultCurrency,
            loginModalRef,
        ],
    );

    const handlePlayForFun = useCallback(
        (gameId: string, isSlots: boolean) => {
            navigateToGame(gameId, isSlots, true);
        },
        [navigateToGame],
    );

    return { handlePlayForFun, handlePlayNow };
};
