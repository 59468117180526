import { FC, PropsWithChildren, useContext } from "react";
import classNames from "classnames";
import { useCollapseMargin, useRuntimeConfig, useTranslation } from "@finbackoffice/site-core";
import { formatAppNameText } from "@finbackoffice/fe-core";
import Link from "next/link";
import { FooterContext } from "components/footer/Footer";
import Translate from "components/base/translate/Translate";
import Img from "components/base/img/Img";
import styles from "./flat.module.sass";

type IProps = {
    title: string;
};

const CollapsingBlok: FC<IProps & PropsWithChildren> = ({ children, title }) => {
    const { opened, animating, headerRef, containerRef } = useCollapseMargin(false);

    return (
        <div className={styles.item}>
            <div ref={headerRef} className={classNames(opened && styles.opened)}>
                {title}
                <i className={styles.arrow} />
            </div>
            {(opened || animating) && <nav ref={containerRef}>{children}</nav>}
        </div>
    );
};

const Flat: FC = () => {
    const { t } = useTranslation();
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const VERSION = useRuntimeConfig("VERSION");
    const {
        enabledBonusesConfig,
        enabledPoliciesConfig,
        renderBonusesLink,
        renderPoliciesLink,
        renderSportLinks,
        renderCasinoLinks,
        renderSocialLinks,
    } = useContext(FooterContext);

    return (
        <footer className={styles.footer}>
            <Link href="/" className={styles.logo}>
                <Img
                    source={`${ASSETS_URL}/${formatAppNameText(
                        COMMON_SITE_CONFIGS.appName,
                    )}/mobile/logo.svg`}
                    alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                    title={`${COMMON_SITE_CONFIGS.appName} logo`}
                    width={0}
                    height={0}
                    style={{ width: "100%", height: "auto" }}
                />
            </Link>
            <CollapsingBlok title={t("footer_sports")}>{renderSportLinks}</CollapsingBlok>
            <CollapsingBlok title={t("footer_casino")}>{renderCasinoLinks}</CollapsingBlok>
            <CollapsingBlok title={t("footer_bonuses")}>
                {enabledBonusesConfig.map(renderBonusesLink)}
            </CollapsingBlok>
            <CollapsingBlok title={t("footer_help_center")}>
                {enabledPoliciesConfig.map(renderPoliciesLink)}
            </CollapsingBlok>
            <Translate
                tid="footer_copyright_text"
                dangerous
                replace={{ app_name: COMMON_SITE_CONFIGS.appName }}>
                <span className={styles.txt} />
            </Translate>
            <div className={styles.socialLinks}>{renderSocialLinks}</div>
            <p className={styles.footerSite}>{`© ${new Date().getFullYear()} ${
                COMMON_SITE_CONFIGS.appName
            }`}</p>
            <p className={styles.version}>
                <Translate tid="footer_version" replace={{ version: VERSION }} />
            </p>
        </footer>
    );
};

export default Flat;
