import { IMaxTicketAmountResponse, IWalletResponse } from "@finbackoffice/clientbff-client";
import { IBetItemData, IBetSlipError, IRuntimeConfig } from "@finbackoffice/fe-core";
import { MarketOutcomeStatus, MarketStatus, TicketType } from "@finbackoffice/enums";

export enum ErrorColors {
    RED = "red",
    GREEN = "green",
    ORANGE = "orange",
}

export interface IBetSlipValidationError {
    errorType: string;
    errorKey: string;
    replace?: Record<string, string | number>;
}

export const BetSlipErrorKeys = {
    min_bet_error: "min_bet_error",
    max_bet_error: "max_bet_error",
    max_win_error: "max_win_error",
    bet_max_min_bet: "bet_max_min_bet",
    select_another_price: "select_another_price",
    special_requirements: "special_requirements",
    mts_error: "mts_error",
};

interface IPerformValidateResults {
    placeBetButtonEnabled: boolean;
    showAcceptButton: boolean;
    error: IBetSlipValidationError | null;
    betItemErrors: Pick<IBetItemData, "outcomeId" | "error">[];
}

interface IPerformBetValidateConfigs {
    isBetProcessorEnabled: boolean;
    maxSelections: number;
    betType: TicketType;
    currentWallet: Partial<IWalletResponse> | null;
    multipleBetStake: string;
    systemBetStake: string;
    systemBetVariants: number;
    maxOdds: number;
    totalOdds: number;
    minBet: number;
    siteMaxWin: number;
    isLoggedIn: boolean;
    betError: IBetSlipError | null;
}

interface IPerformBetItemValidateResults {
    placeBetDisabled: boolean;
}

export const ErrorKeys = {
    SELECT_BET: "betSlip_selectBetError",
    BET_CHANGE: "betSlip_betChangeError",
    MAX_SELECTIONS: "betSlip_maxSelectionsError",
    STAKE_LESS: "betSlip_stakeLessError",
    LOGIN_ERROR: "betSlip_loginError",
    NOT_ENOUGH_BALANCE: "betSlip_notEnoughBalance",
    MULTIPLE_BET_PICK_REACHED_MIN_REQUIREMENT: "betSlip_multipleBetPickReachedMinRequirementError",
    MULTIPLE_BET_MIN: "betSlip_multipleBetMinError",
    MAX_ODDS: "betSlip_maxOddsError",
    POSSIBLE_WIN_MAX: "betSlip_possibleWinMaxError",
    SYSTEM_BET_MIN: "betSlip_systemBetMinError",
};

const DEFAULT_RESULTS = {
    placeBetButtonEnabled: true,
    showAcceptButton: false,
    error: null,
    betItemErrors: [],
};

export const ERROR_CODES = {
    mts: {
        match: [401, 402, 440],
        outcomeChanged: [421, 420, 422, 423, 430, 431, 432, 433, 441],
        notActive: [403, 404, 405, 406, 407, 408, 409, 410, 442],
    },
};

export const NEED_TO_REMOVE_ERROR_CODES = [...ERROR_CODES.mts.match, ...ERROR_CODES.mts.notActive];
export const isErrorNeedToRemove = (errorCode?: number) =>
    (errorCode && NEED_TO_REMOVE_ERROR_CODES.includes(errorCode)) ||
    (errorCode && errorCode >= 100 && errorCode <= 400) ||
    (errorCode && errorCode >= 500 && errorCode <= 800);
export const isMtsError = (err?: string) => err === BetSlipErrorKeys.mts_error;

export const outcomeRemoved = (outcomeStatus: MarketOutcomeStatus) =>
    outcomeStatus === MarketOutcomeStatus.NotActive;
export const needToRemove = (marketStatus: MarketStatus, outcomeStatus: MarketOutcomeStatus) =>
    [MarketStatus.OnHold, MarketStatus.WaitingForResult, MarketStatus.Settled].indexOf(
        marketStatus,
    ) !== -1 || outcomeRemoved(outcomeStatus);

export const OUTCOME_CHANGED_ERROR_CODES = ERROR_CODES.mts.outcomeChanged;
export const isErrorOutcomeChanged = (errorCode?: number) =>
    errorCode && OUTCOME_CHANGED_ERROR_CODES.includes(errorCode);

export const isOutcomeChanged = (item: IBetItemData) => item.outcomeInitValue !== item.outcomeValue;

const filterAccepted = (betItems: IBetItemData[]) => betItems.filter((item) => !item.accepted);

export const validationRules = {
    getChangedBets: (betItems: IBetItemData[]) =>
        betItems.filter(
            (item) =>
                !item.accepted &&
                (isOutcomeChanged(item) || isErrorOutcomeChanged(item.error?.code)),
        ),
    getRemovableBets: (betItems: IBetItemData[]) =>
        betItems.filter(
            (item) =>
                !item.accepted &&
                (needToRemove(item.marketStatus, item.outcomeStatus) ||
                    (isMtsError(item.error?.error) && isErrorNeedToRemove(item.error?.code))),
        ),
    isBetSlipEmpty: (betItems: IBetItemData[]) => filterAccepted(betItems).length === 0,
    getEmptyStakeBets: (betItems: IBetItemData[]) =>
        betItems.filter((item) => !item.accepted && !item.stake),
    getBetsWithPickReached: (betItems: IBetItemData[]) =>
        betItems.filter(
            (item) =>
                !item.accepted &&
                item.limits &&
                parseFloat(item.limits?.amount) < parseFloat(item.limits?.min_bet),
        ),
    getBetsWithLessStakeThanMinBet: (betItems: IBetItemData[]) =>
        betItems.filter(
            (item) =>
                !item.accepted &&
                item.limits &&
                validationRules.isStakeLessThanMinBet(
                    parseFloat(item.stake),
                    parseFloat(item.limits.min_bet),
                ),
        ),
    isStakeLessThanMinBet: (stake: number, minBet: number) => stake < minBet,
    isBalanceEnough: (stake: number, balance: number) => stake <= balance,
    isExpressItemsAmountMinValid: (minCount: number, betItems: IBetItemData[]) =>
        filterAccepted(betItems).length >= minCount,
    isSystemItemsAmountMinValid: (minCount: number, betItems: IBetItemData[]) =>
        filterAccepted(betItems).length >= minCount,
    isSelectionsAmountMaxValid: (betItems: IBetItemData[], max: number) =>
        filterAccepted(betItems).length <= max,
    isPossibleWinValid: (stake: number, totalOdds: number, maxWin: number) =>
        stake * totalOdds <= maxWin,
    isMaxOddsValid: (totalOdds: number, maxOdds: number) => totalOdds <= maxOdds,
    doesAnyBetHaveError: (betItems: IBetItemData[], betError: IBetSlipError | null) =>
        betItems.some(
            (item) => !item.accepted && Boolean(item.error?.error) && Boolean(item.error?.visible),
        ) ||
        (!!betError?.error && betError.visible),
};

const performSingleBetValidate = (
    betItems: IBetItemData[],
    configs: IPerformBetValidateConfigs,
): IPerformValidateResults | null => {
    const betsWithPickReached = validationRules.getBetsWithPickReached(betItems);

    if (betsWithPickReached.length) {
        const betItemErrors: Pick<IBetItemData, "outcomeId" | "error">[] = [];
        betsWithPickReached.forEach((item) => {
            if (!item.error) {
                betItemErrors.push({
                    outcomeId: item.outcomeId,
                    error: {
                        error: "select_another_price",
                        isPositive: false,
                        visible: true,
                    },
                });
            }
        });
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            betItemErrors,
        };
    }

    const emptyStakeBets = validationRules.getEmptyStakeBets(betItems);
    const betsWithLessStakeThanMinBet = configs.isBetProcessorEnabled
        ? validationRules.getBetsWithLessStakeThanMinBet(betItems)
        : [];

    if (Boolean(emptyStakeBets.length) || Boolean(betsWithLessStakeThanMinBet.length)) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.STAKE_LESS,
            },
        };
    }

    const totalStake = betItems.reduce(
        (accumulator, currentValue) =>
            accumulator + (currentValue.stake ? parseFloat(currentValue.stake) : 0),
        0,
    );

    if (
        configs.isLoggedIn &&
        !validationRules.isBalanceEnough(
            totalStake,
            parseFloat(configs.currentWallet?.balance || "0"),
        )
    ) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.NOT_ENOUGH_BALANCE,
            },
        };
    }

    return null;
};

const performExpressBetValidate = (
    commonSiteConfigs: IRuntimeConfig["COMMON_SITE_CONFIGS"],
    betItems: IBetItemData[],
    configs: IPerformBetValidateConfigs,
): IPerformValidateResults | null => {
    const betsWithPickReached = validationRules.getBetsWithPickReached(betItems);

    if (Boolean(betsWithPickReached.length) && betItems.length < 4) {
        const betItemErrors: Pick<IBetItemData, "outcomeId" | "error">[] = [];

        betsWithPickReached.forEach((item) => {
            if (item.error?.error !== BetSlipErrorKeys.special_requirements) {
                betItemErrors.push({
                    outcomeId: item.outcomeId,
                    error: {
                        error: BetSlipErrorKeys.special_requirements,
                        isPositive: false,
                        visible: true,
                    },
                });
            }
        });
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.MULTIPLE_BET_PICK_REACHED_MIN_REQUIREMENT,
            },
            betItemErrors,
        };
    }

    if (Boolean(betsWithPickReached.length) && betItems.length >= 4) {
        const betItemErrors: Pick<IBetItemData, "outcomeId" | "error">[] = [];

        betsWithPickReached.forEach((item) => {
            if (item.error && !item.error.isPositive) {
                betItemErrors.push({
                    outcomeId: item.outcomeId,
                    error: null,
                });
            }
        });

        return {
            ...DEFAULT_RESULTS,
            betItemErrors,
        };
    }

    if (
        !validationRules.isExpressItemsAmountMinValid(
            commonSiteConfigs.betslip.expressMinCount,
            betItems,
        )
    ) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.MULTIPLE_BET_MIN,
            },
        };
    }

    if (
        configs.isBetProcessorEnabled &&
        !validationRules.isMaxOddsValid(configs.totalOdds, configs.maxOdds)
    ) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.MAX_ODDS,
                replace: {
                    value: configs.maxOdds,
                },
            },
        };
    }

    const stake = configs.multipleBetStake ? parseFloat(configs.multipleBetStake) : 0;

    if (
        !stake ||
        (configs.isBetProcessorEnabled &&
            validationRules.isStakeLessThanMinBet(stake, configs.minBet))
    ) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.STAKE_LESS,
            },
        };
    }

    if (
        configs.isLoggedIn &&
        !validationRules.isBalanceEnough(stake, parseFloat(configs.currentWallet?.balance || "0"))
    ) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.NOT_ENOUGH_BALANCE,
            },
        };
    }

    if (
        configs.isBetProcessorEnabled &&
        !validationRules.isPossibleWinValid(stake, configs.totalOdds, configs.siteMaxWin)
    ) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.POSSIBLE_WIN_MAX,
            },
        };
    }

    return null;
};

const performSystemBetValidate = (
    commonSiteConfigs: IRuntimeConfig["COMMON_SITE_CONFIGS"],
    betItems: IBetItemData[],
    configs: IPerformBetValidateConfigs,
): IPerformValidateResults | null => {
    const stake = configs.systemBetStake ? parseFloat(configs.systemBetStake) : 0;
    if (
        !validationRules.isSystemItemsAmountMinValid(commonSiteConfigs.betslip.system.min, betItems)
    ) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.SYSTEM_BET_MIN,
            },
        };
    }

    if (
        !stake ||
        (configs.isBetProcessorEnabled &&
            validationRules.isStakeLessThanMinBet(stake, configs.minBet))
    ) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.STAKE_LESS,
            },
        };
    }

    if (
        configs.isLoggedIn &&
        !validationRules.isBalanceEnough(
            stake * configs.systemBetVariants,
            parseFloat(configs.currentWallet?.balance || "0"),
        )
    ) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.NOT_ENOUGH_BALANCE,
            },
        };
    }

    if (
        configs.isBetProcessorEnabled &&
        !validationRules.isPossibleWinValid(stake, configs.totalOdds, configs.siteMaxWin)
    ) {
        return {
            ...DEFAULT_RESULTS,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.POSSIBLE_WIN_MAX,
            },
        };
    }

    return null;
};

export const performBetValidate = (
    commonSiteConfigs: IRuntimeConfig["COMMON_SITE_CONFIGS"],
    betItems: IBetItemData[],
    configs: IPerformBetValidateConfigs,
): IPerformValidateResults => {
    const initialResult = DEFAULT_RESULTS;

    if (validationRules.isBetSlipEmpty(betItems)) {
        return {
            ...initialResult,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.GREEN,
                errorKey: ErrorKeys.SELECT_BET,
            },
        };
    }

    const changedBets = validationRules.getChangedBets(betItems);
    const removableBets = validationRules.getRemovableBets(betItems);

    if (Boolean(changedBets.length) || Boolean(removableBets.length)) {
        return {
            ...initialResult,
            placeBetButtonEnabled: false,
            showAcceptButton: true,
            error: {
                errorType: ErrorColors.ORANGE,
                errorKey: ErrorKeys.BET_CHANGE,
            },
        };
    }

    if (
        configs.isBetProcessorEnabled &&
        !validationRules.isSelectionsAmountMaxValid(betItems, configs.maxSelections)
    ) {
        return {
            ...initialResult,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.RED,
                errorKey: ErrorKeys.MAX_SELECTIONS,
                replace: {
                    value: configs.maxSelections,
                },
            },
        };
    }

    let result;
    switch (configs.betType) {
        case TicketType.Single:
            result = performSingleBetValidate(betItems, configs);
            break;
        case TicketType.Express:
            result = performExpressBetValidate(commonSiteConfigs, betItems, configs);
            break;
        case TicketType.System:
            result = performSystemBetValidate(commonSiteConfigs, betItems, configs);
            break;
        default:
    }
    if (result) {
        return result;
    }

    if (validationRules.doesAnyBetHaveError(betItems, configs.betError)) {
        return {
            ...initialResult,
            placeBetButtonEnabled: false,
        };
    }

    if (!configs.isLoggedIn) {
        return {
            ...initialResult,
            placeBetButtonEnabled: false,
            error: {
                errorType: ErrorColors.GREEN,
                errorKey: ErrorKeys.LOGIN_ERROR,
            },
        };
    }

    return initialResult;
};

export const validateStake = (
    stake: string,
    error?: IBetSlipError | null,
    limits?: IMaxTicketAmountResponse,
): IBetSlipError | null => {
    let visible = false;
    if (!!error?.error) {
        if (error.error === BetSlipErrorKeys.max_bet_error && error.isPositive) {
            if (limits && parseFloat(stake) > parseFloat(limits.amount)) {
                visible = true;
            }

            return { ...error, visible };
        }

        if (error.error === BetSlipErrorKeys.min_bet_error && error.isPositive) {
            if (limits && parseFloat(stake) < parseFloat(limits.min_bet)) {
                visible = true;
            }

            return { ...error, visible };
        }
    }

    return null;
};

export const performBetItemValidate = (
    bet: IBetItemData,
    configs: Partial<IPerformBetValidateConfigs>,
): IPerformBetItemValidateResults => {
    if (
        isOutcomeChanged(bet) ||
        isErrorOutcomeChanged(bet.error?.code) ||
        needToRemove(bet.marketStatus, bet.outcomeStatus) ||
        isErrorNeedToRemove(bet.error?.code) ||
        bet.stake === "" ||
        (configs.isBetProcessorEnabled &&
            bet.limits &&
            validationRules.isStakeLessThanMinBet(
                parseFloat(bet.stake),
                parseFloat(bet.limits.min_bet),
            )) ||
        (configs.currentWallet &&
            !validationRules.isBalanceEnough(
                parseFloat(bet.stake),
                parseFloat(configs.currentWallet.balance || "0"),
            )) ||
        !configs.isLoggedIn
    ) {
        return { placeBetDisabled: true };
    }
    return { placeBetDisabled: false };
};
