import { FC, useContext, useMemo, useState } from "react";
import { UserStatus } from "@finbackoffice/enums";
import {
    ConfigContext,
    getCountries,
    ModalsContext,
    useAccountConfig,
    userInfoValidationSchema,
    useRuntimeConfig,
    useTranslation,
} from "@finbackoffice/site-core";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InferType } from "yup";
import Button from "components/base/button/Button";
import Loading from "components/base/loading/Loading";
import Translate from "components/base/translate/Translate";
import Dropdown from "../../base/dropdown/Dropdown";
import Input from "../../base/input-field/Input";
import VerificationKycItem from "./item/VerificationKycItem";
import styles from "./verification-kyc.module.sass";

// @TODO remove after signup kyc backend implementation
const MOCK_USER_DATA = {
    email: "email@gmail.com",
    phone: "+5521200200",
    email_verified: false,
    phone_verified: false,
    status: UserStatus.Internal,
};

type InfoForm = InferType<typeof userInfoValidationSchema>;

const VerificationKyc: FC = () => {
    // const { userData } = useContext(UserAccountContext);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { verifyModalRef } = useContext(ModalsContext);
    const { siteRegistrationConfig } = useContext(ConfigContext);
    const { t } = useTranslation();
    // const { accountConfig } = useContext(ConfigContext);
    const accountConfigObj = useAccountConfig();
    const [showLaterMessage, setShowLaterMessage] = useState<boolean>(false);

    // @TODO remove after signup kyc backend implementation
    const [userData, setUserData] = useState<typeof MOCK_USER_DATA>(MOCK_USER_DATA);

    const onFinishClickHandler = async () => {
        // @TODO implement profile data update
        verifyModalRef.current?.close();
    };

    const onLater = async () => {
        setShowLaterMessage(true);
    };

    const defaultValuesMemo = useMemo(() => {
        const defaultValues: any = {} as InfoForm;
        // accountConfig?.forEach((field) => {
        //     defaultValues[field.name as keyof InfoForm] =
        //         userData?.[field.name as keyof InfoForm] || "";
        // });
        return defaultValues;
    }, []);

    const {
        control,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
        context: { configObj: { ...accountConfigObj, t } },
        resolver: yupResolver(
            userInfoValidationSchema.pick([
                ...(Object.keys(accountConfigObj) as (keyof InfoForm)[]),
            ]),
        ),
        defaultValues: defaultValuesMemo,
    });

    if (!userData) return <Loading />;

    return (
        <>
            <div className={styles.title}>
                <Translate tid="verification_kyc_heading" />
            </div>
            {showLaterMessage ? (
                <div className={styles.laterMessage}>
                    <p>
                        <Translate tid="verification_kyc_later" />
                    </p>
                    <Button
                        type="button"
                        variant="primary"
                        onClick={() => verifyModalRef.current?.close()}>
                        <Translate tid="base_ok" />
                    </Button>
                </div>
            ) : (
                <div className={styles.verification} data-testid="verification-container">
                    <div className={styles.subTitle}>
                        <Translate tid="verification_kyc_sub_heading" />
                        <span>
                            <Translate tid="verification_kyc_description" />
                        </span>
                    </div>
                    {userData && siteRegistrationConfig ? (
                        <>
                            {!siteRegistrationConfig.require_phone_verification && (
                                <VerificationKycItem
                                    type="phone"
                                    data={userData.phone}
                                    verified={userData.phone_verified}
                                    onVerified={() =>
                                        setUserData({ ...userData, phone_verified: true })
                                    }
                                />
                            )}
                            {!siteRegistrationConfig.require_email_verification && (
                                <VerificationKycItem
                                    type="email"
                                    data={userData.email}
                                    verified={userData.email_verified}
                                    onVerified={() =>
                                        setUserData({ ...userData, email_verified: true })
                                    }
                                />
                            )}
                            <form>
                                <div className={styles.row}>
                                    <Controller
                                        render={({ field: { onChange, value, name } }) => {
                                            return (
                                                <Dropdown
                                                    name={name}
                                                    onChange={onChange}
                                                    value={value}
                                                    label="userData_country"
                                                    options={getCountries(
                                                        COMMON_SITE_CONFIGS.country.supported,
                                                    )}
                                                    error={errors.country}
                                                    disabled={!accountConfigObj.country?.editable}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="country"
                                    />

                                    <Controller
                                        render={({ field: { onChange, value, name } }) => {
                                            return (
                                                <Input
                                                    label="userData_state"
                                                    name={name}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={errors.state}
                                                    disabled={!accountConfigObj.address?.editable}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="state"
                                    />
                                </div>
                                <div className={styles.row}>
                                    <Controller
                                        render={({ field: { onChange, value, name } }) => {
                                            return (
                                                <Input
                                                    label="userData_city"
                                                    name={name}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={errors.city}
                                                    disabled={!accountConfigObj.city?.editable}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="city"
                                    />

                                    <Controller
                                        render={({ field: { onChange, value, name } }) => {
                                            return (
                                                <Input
                                                    label="userData_address"
                                                    name={name}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={errors.address}
                                                    disabled={!accountConfigObj.address?.editable}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="address"
                                    />
                                </div>
                            </form>
                        </>
                    ) : (
                        <Loading />
                    )}
                    <div className={styles.buttonContainer}>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={onLater}
                            disabled={userData?.status === UserStatus.Draft}>
                            <Translate tid="verification_later" />
                        </Button>
                        <Button
                            type="button"
                            variant="primary"
                            onClick={onFinishClickHandler}
                            disabled={
                                !isValid && !userData.email_verified && !userData.phone_verified
                            }>
                            <Translate tid="verification_kyc_complete" />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default VerificationKyc;
