import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Settings } from "react-slick";
import { IEventFeedResponse, ISportFeedResponse } from "@finbackoffice/websocket-client";
import { useDidUpdateEffect } from "@finbackoffice/site-core";
import { sortByScheduledTime } from "@finbackoffice/fe-core";
import SportsLayout from "components/layout/sportsbook/SportsLayout";
import SlickSlider from "components/base/slick-slider/SlickSlider";
import { convertGamesToSports } from "utils/helpers";
import { InitialDataContext } from "contexts";
import HomeTournamentGameItem from "../../tournament/game/HomeTournamentGameItem";

type IProps = {
    events: IEventFeedResponse[];
    sports?: ISportFeedResponse[];
    activeSportId: number | null;
    onSportClickHandler: (sportId: number) => void;
    sliderSpeed: number;
};

const SportGamesContent: FC<IProps> = ({
    events,
    activeSportId,
    onSportClickHandler,
    sliderSpeed,
    sports,
}) => {
    const { setActiveSportGame, activeSportGame } = useContext(InitialDataContext);
    const [ready, setReady] = useState(!!activeSportId);

    const sportsMemo = useMemo(() => sports || convertGamesToSports(events), [events, sports]);

    const activeSportEvents = useMemo(
        () => events.filter((event) => event.sport_id === activeSportId).sort(sortByScheduledTime),
        [activeSportId, events],
    );

    const getActiveTournamentEvents = useCallback(
        (gameId: number) => {
            const selectedEvent = activeSportEvents.find((game) => gameId && game.id === gameId);
            const selectedEventTournamentId = selectedEvent?.tournament_id;
            const selectedTournamentEvents = events.filter(
                (game) => game.tournament_id === selectedEventTournamentId,
            );

            return selectedTournamentEvents;
        },
        [activeSportEvents, events],
    );

    const sliderSettings: Settings = useMemo(
        () => ({
            dots: false,
            variableWidth: true,
            infinite: activeSportEvents.length > 1,
            autoplay: activeSportEvents.length > 1,
            autoplaySpeed: sliderSpeed,
            speed: 700,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        }),
        [activeSportEvents, sliderSpeed],
    );

    useDidUpdateEffect(() => {
        if (ready) {
            setReady(false);
        }
    }, [activeSportId]);

    useDidUpdateEffect(() => {
        if (!ready && activeSportId) {
            setReady(true);
        }
    }, [ready, activeSportId]);

    useEffect(() => {
        if (activeSportGame.gameId && activeSportGame.tournamentGames === null) {
            setActiveSportGame((state) => {
                if (activeSportGame.gameId) {
                    return {
                        ...state,
                        tournamentGames: getActiveTournamentEvents(
                            parseInt(activeSportGame.gameId),
                        ),
                    };
                }
                return state;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onGameClickHandler = useCallback(
        (gameId: number) => {
            if (gameId) {
                setActiveSportGame({
                    gameId: gameId.toString(),
                    tournamentGames: getActiveTournamentEvents(gameId),
                });
            }
        },
        [getActiveTournamentEvents, setActiveSportGame],
    );

    return (
        <SportsLayout
            variant="widget"
            sports={sportsMemo}
            activeSportId={activeSportId ?? undefined}
            onSportClick={onSportClickHandler}>
            {ready && (
                <SlickSlider settings={sliderSettings}>
                    {activeSportEvents.map((game, index) => (
                        <HomeTournamentGameItem
                            key={game.id}
                            game={game}
                            layout="top"
                            index={index}
                            onGameClickHandler={onGameClickHandler}
                        />
                    ))}
                </SlickSlider>
            )}
        </SportsLayout>
    );
};

export default SportGamesContent;
