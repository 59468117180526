import { FC } from "react";
import { ReactSVG, Props } from "react-svg";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import { CircleLoader } from "../circle-loader/CircleLoader";

const Loader = () => <CircleLoader />;

type IProps = Props & {
    loader?: boolean;
    variant?: "default" | "gif";
};

// https://github.com/tanem/react-svg/issues/1545
export const Svg: FC<Omit<IProps, "ref">> = (props) => {
    const { loader, src, variant = "default", ...rest } = props;
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");

    if (variant === "gif") {
        return (
            <span className={rest.className}>
                <object type="image/svg+xml" data={`${ASSETS_URL}${src}`}></object>
            </span>
        );
    }

    return <ReactSVG src={`${ASSETS_URL}${src}`} {...rest} loading={loader ? Loader : undefined} />;
};
