export const ModalTypes = {
    MULTIPLE_SELECTOR_CURRENCY: "multipleSelectorCurrency",
    MULTIPLE_SELECTOR_OPERATIONS: "multipleSelectorOperations",
    SIGNUP: "signup",
    VERIFICATION: "verification",
    VERIFICATION_KYC: "verification_kyc",
    VERIFICATION_ID: "verification_id",
    LOGIN: "login",
    FORGOT_PASS: "forgotPass",
    SETTINGS: "settings",
    ACCOUNT: "account",
    ACCOUNT_MESSAGES: "accountMessages",
    ACCOUNT_BETS_HISTORY: "accountBetsHistory",
    ACCOUNT_REFERRAL_REPORT: "accountReferralReport",
    PROFILE_MAIN_SECTION_MODAL: "profileMainSectionModal",
    "2FA": "2fa",
    BET_SLIP: "betSlip",
    WALLET: "wallet",
    WALLET_VERIFY_TRANSACTION: "walletVerifyTransaction",
    PHONE_VERIFICATION: "phoneVerification",
    EMAIL_VERIFICATION: "emailVerification",
    BET_DETAILS: "betDetails",
    SITE_NOTIFICATIONS: "siteNotifications",
    CASINO_FAVORITES: "casinoFavorites",
    CASINO_FAVORITE_THUMBNAIL: "casinoFavoriteThumbnail",
    REELS_GAME: "reelsGame",
    CASINO_SEARCH_CONTAINER: "casinoSearchContainer",
    CASINO_TOOLS: "casinoTools",
    BANNERS: "banners",
    GAME: "game",
    RESPONSIBLE_GAMING_CONFIRMATION: "responsibleGamingConfirmation",
    INACTIVITY: "inactivity",
    BONUSES: "bonuses",
};
