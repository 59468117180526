import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import SIRWidgetConfigs from "../components/base/widgets/sir/SIRWidgetConfigs";

type ISIRWidgetContext = {
    checkSupportedSport?: (sportId: number) => boolean;
    checkSupportedCompactSport?: (sportId: number) => boolean;
    checkIfTeamInverted?: (sportId: number, regionId: number) => boolean;
    isLicenced?: boolean;
    setIsLicenced?: (isLicenced: boolean) => void;
    loading?: boolean;
    setLoading?: (loading: boolean) => void;
};

export const SIRWidgetContext = createContext<ISIRWidgetContext>({});

export const SIRWidgetProvider: FC<PropsWithChildren> = ({ children }) => {
    const { supportedSports, supportedCompactSports, sportRegionTeamInverted } = SIRWidgetConfigs();
    const [isLicenced, setIsLicenced] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);

    const checkSupportedSport = useCallback(
        (sportId: number) => supportedSports.includes(sportId),
        [supportedSports],
    );

    const checkSupportedCompactSport = useCallback(
        (sportId: number) => supportedCompactSports.includes(sportId),
        [supportedCompactSports],
    );

    const checkIfTeamInverted = useCallback(
        (sportId: number, regionId: number) =>
            sportRegionTeamInverted[sportId]
                ? sportRegionTeamInverted[sportId].length
                    ? sportRegionTeamInverted[sportId].includes(regionId)
                    : true
                : false,
        [sportRegionTeamInverted],
    );

    const value = useMemo(
        () => ({
            checkSupportedSport,
            checkSupportedCompactSport,
            checkIfTeamInverted,
            isLicenced,
            setIsLicenced,
            loading,
            setLoading,
        }),
        [checkSupportedSport, checkSupportedCompactSport, checkIfTeamInverted, isLicenced, loading],
    );

    return <SIRWidgetContext.Provider value={value}>{children}</SIRWidgetContext.Provider>;
};
