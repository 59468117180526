import { ModalsContext } from "@finbackoffice/site-core";
import { useContext, useEffect, useRef } from "react";

export const useClearModalOnUnmount = (modalType: any) => {
    const { currentModal, clearCurrentModal } = useContext(ModalsContext);
    const currentModalRef = useRef(currentModal);

    useEffect(() => {
        currentModalRef.current = currentModal;
    }, [currentModal]);

    useEffect(() => {
        return () => {
            if (currentModalRef?.current === modalType) {
                clearCurrentModal();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
