import { useCallback, useContext, useMemo, useState } from "react";
import { IClientError, ISocketResponse } from "@finbackoffice/websocket-client";
import { AuthContext, useWebsocketUpdates } from "@finbackoffice/site-core";

export const useChat = (): ISocketResponse<"chat_message">[] => {
    const { userToken } = useContext(AuthContext);
    const [data, setData] = useState<ISocketResponse<"chat_message">[]>([]);

    const handleChatUpdates = useCallback(
        (messages: ISocketResponse<"chat_message">, error: IClientError) => {
            if (!error) {
                setData((state) => [...state, messages]);
            } else {
                console.error(error);
            }
        },
        [],
    );
    const params = useMemo(() => ({ token: userToken || "" }), [userToken]);

    useWebsocketUpdates("chat_message", handleChatUpdates, true, params);

    return data;
};
