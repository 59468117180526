import { createContext, FC, memo, useCallback, useContext, useMemo } from "react";
import { IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import { IEventFeedResponse } from "@finbackoffice/websocket-client";
import { BetSlipContext, IMainOddsState, useMainMarketOdds } from "@finbackoffice/site-core";
import { MarketOutcomeStatus, MarketStatus } from "@finbackoffice/enums";
import dynamic from "next/dynamic";
import { MarketUpdatesContext } from "contexts";

const Default = dynamic(() => import("./layouts/default/Default"), {
    ssr: false,
});

const Top = dynamic(() => import("./layouts/top/Top"), {
    ssr: false,
});

type Props = {
    game: IEventFeedResponse;
    layout?: "default" | "top";
    index: number;
    onGameClickHandler?: (gameId: number) => void;
};

type IHomeTournamentGameItemContext = {
    game: IEventFeedResponse;
    mainMarketId: number | null;
    mainOdds?: IMainOddsState;
    mainMarketStatus?: MarketStatus;
    onOutcomeClick: (outcome: IMarketOutcomeFeedResponse | null) => void;
    columnCount: string;
    isInternationalCategory: boolean;
    index: number;
};

export const HomeTournamentGameItemContext = createContext<IHomeTournamentGameItemContext>(
    null as any,
);

const HomeTournamentGameItem: FC<Props> = ({
    game,
    index,
    onGameClickHandler,
    layout = "default",
}) => {
    const { addBetItem, removeBetItem, isOutcomeSelected } = useContext(BetSlipContext);
    const { subscribeMarket, unsubscribeMarket } = useContext(MarketUpdatesContext);
    const gameId = game?.id;
    const homeName = game?.home_team?.name || "";
    const awayName = game?.away_team?.name || "";
    const sportId = game?.sport_id;
    const {
        mainOdds,
        mainMarketName,
        mainMarketStatus,
        mainMarketTemplateId,
        mainMarketId,
        columnCount,
    } = useMainMarketOdds(sportId, game.markets);
    const isInternationalCategory = game.category_id === 4;

    const onOutcomeClick = useCallback(
        (outcome: IMarketOutcomeFeedResponse | null) => {
            if (mainMarketId && mainMarketStatus && outcome) {
                if (!isOutcomeSelected(outcome.id)) {
                    addBetItem({
                        gameId,
                        marketGroupId: mainMarketTemplateId?.toString() || "",
                        marketId: mainMarketId,
                        outcomeId: outcome.id,
                        team1Name: homeName,
                        team2Name: awayName,
                        marketName: mainMarketName,
                        marketStatus: mainMarketStatus,
                        outcomeName: outcome?.name || "",
                        outcomeValue: outcome.odds,
                        outcomeStatus: MarketOutcomeStatus.Active,
                        outcomeInitValue: outcome.odds,
                        stake: "",
                        loading: false,
                        accepted: false,
                    });
                    subscribeMarket(gameId, mainMarketId, outcome.id);
                } else {
                    removeBetItem(outcome.id);
                    unsubscribeMarket(outcome.id);
                }
            }
        },
        [
            awayName,
            isOutcomeSelected,
            gameId,
            homeName,
            mainMarketName,
            mainMarketStatus,
            mainMarketTemplateId,
            mainMarketId,
            subscribeMarket,
            unsubscribeMarket,
            addBetItem,
            removeBetItem,
        ],
    );

    const value = useMemo(
        () => ({
            game,
            mainMarketId,
            mainOdds,
            mainMarketStatus,
            onOutcomeClick,
            columnCount,
            isInternationalCategory,
            index,
        }),
        [
            game,
            mainMarketId,
            mainOdds,
            mainMarketStatus,
            onOutcomeClick,
            columnCount,
            isInternationalCategory,
            index,
        ],
    );

    const renderLayout = useMemo(() => {
        switch (layout) {
            case "top":
                return !!onGameClickHandler && <Top onGameClickHandler={onGameClickHandler} />;
            default:
                return <Default />;
        }
    }, [layout, onGameClickHandler]);

    return (
        <HomeTournamentGameItemContext.Provider value={value}>
            {renderLayout}
        </HomeTournamentGameItemContext.Provider>
    );
};

export default memo(HomeTournamentGameItem);
