import { FC, useContext } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import classNames from "classnames";
import { useTranslation } from "@finbackoffice/site-core";
import Button from "components/base/button/Button";
import Img from "components/base/img/Img";
import Translate from "components/base/translate/Translate";
import Video from "components/base/video/Video";
import { CasinoGameItemContext } from "../../CasinoGameItem";
import styles from "./rounded.module.sass";

const Rounded: FC = () => {
    const {
        game,
        handlePlayNow,
        gameName,
        isUserLoggedIn,
        isFavorite,
        handleFavoriteClick,
        fallbackSrc,
        onReady,
        isVideo,
        handleVideoError,
        gameImgSrc,
        onFallbackHandler,
        backImg,
    } = useContext(CasinoGameItemContext);
    const { t } = useTranslation(TranslationScopes.Casino);
    const title = `${t(`casino_${game.type.replaceAll("-", "_")}`)} ${gameName}`;
    const fallbackSrcValue = fallbackSrc || game.image_square;

    return (
        <div className={classNames("casino-game-item", styles.gameItem)}>
            {backImg && (
                <Img
                    source={gameImgSrc}
                    alt={title}
                    title={title}
                    width={220}
                    height={260}
                    wrapperClassName={styles.bluredBg}
                    fallbackSrc={fallbackSrcValue}
                    onFallback={onFallbackHandler}
                />
            )}
            <div>
                <span className={styles.gameCategory}>{game.category}</span>
                <div>
                    {isVideo ? (
                        <Video
                            src={gameImgSrc}
                            onError={handleVideoError}
                            onLoad={() => onReady?.()}
                        />
                    ) : (
                        <Img
                            source={gameImgSrc}
                            alt={title}
                            title={title}
                            width={0}
                            height={0}
                            style={{ width: 150, height: "auto" }}
                            fallbackSrc={fallbackSrcValue}
                            onFallback={onFallbackHandler}
                            onLoad={() => onReady?.()}
                        />
                    )}
                    {isUserLoggedIn && (
                        <i
                            className={classNames(styles.unstarred, isFavorite && styles.starred)}
                            onClick={handleFavoriteClick}
                        />
                    )}
                </div>
                <p className={styles.gameName} title={game.name}>
                    <span>{game.name}</span>
                </p>
                <Button
                    type="button"
                    variant="secondary"
                    className={styles.playNowBtn}
                    onClick={handlePlayNow}>
                    <Translate tid="casino_play_now" namespace={TranslationScopes.Casino} />
                </Button>
            </div>
        </div>
    );
};

export default Rounded;
